import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import * as yup from 'yup';
import { Api } from '../../services/Api';
import { useUser } from "../../services/UserContext";
import { UserLevels } from "../../services/UserLevels";
import { UserRoles } from "../../services/UserRoles";
import { EntityFormField } from "../EntityFormField";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { ImportEntitiesForm } from "../ImportEntitiesForm";
import { Label } from '../Label';
import { Loader } from "../Loader";
import { UserScopeEditor } from "./UserScopeEditor";

const ImportUsersForm = () => {
    const ROOT_OZMO_TENANT_CODE = 'CLBR';
    const user = useUser();
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    const [ozmoCodesCache, setOzmoCodesCache] = useState({});

    const { setFieldValue } = useFormikContext();
    const [ozmoTenantCode] = useField('ozmoTenantCode');
    const [level] = useField('level');
    const [tenant] = useField('tenant');
    const [role, , roleHelpers] = useField('role');
    const [upns, , upnsHelpers] = useField('upns');

    useEffect(() => {

        const setCode = (tenantCode) => {
            if (tenantCode !== ozmoTenantCode.value) {
                // Note: we should avoid formik and go with react form hook (https://www.react-hook-form.com/) instead.
                // Dont use setValue method from useField otherwise we end up to infinite loop.
                // cf https://github.com/jaredpalmer/formik/issues/3602
                // >> https://github.com/jaredpalmer/formik/issues/3602#issuecomment-1508599437
                // >> Hi! Are there any updates regarding this issue? I've ran into this issue 2.2.6 and it seems to be relevant on the latest patch too.
                // >> Also, to add to the workaround conversation, it seems to me that this issue only affects setValue of useField hook, but doesn't affect useFormikContext's setFieldValue.I managed to refactor code to use setFieldValue and everything works as expected.
                // >> In any case this should be noted for anyone trying to upgrade to React 18, that this kind of issue can happen and it can hang the whole website.Pretty dangerous

                setFieldValue("upns", []);
                setFieldValue("ozmoTenantCode", tenantCode);

                if (tenantCode) {
                    setUsersLoading(true);
                    const request = Api.getImportableUsers({ ozmoTenantCode: tenantCode });
                    request
                        .then(data => {
                            if (!request.aborted) {
                                setUsers(data);
                            }
                        }).catch(error => {
                            if (!request.aborted) {
                                setUsers([]);
                                alert(error.msg);
                            }
                        }).finally(() => {
                            setUsersLoading(false);
                        });
                } else {
                    setUsers([]);
                }
            }
        };

        if (level.value === UserLevels.ROOT) {
            setCode(ROOT_OZMO_TENANT_CODE);
        } else if (tenant.value && tenant.value.id) {
            if (tenant.value.ozmoCode) {
                setCode(tenant.value.ozmoCode);
            } else {
                var ozmoCode = ozmoCodesCache[tenant.value.id];
                if (ozmoCode) {
                    setCode(ozmoCode);
                } else {
                    const request = Api.getTenant(tenant.value.id);
                    request
                        .then(data => {
                            if (!request.aborted) {
                                setCode(ozmoCode);
                                setOzmoCodesCache(prevState => ({
                                    ...prevState,
                                    [tenant.value.id]: data.ozmoCode
                                }));
                            }
                        }).catch(error => {
                            if (!request.aborted) {
                                setCode(undefined);
                                alert(error.msg);
                            }
                        });
                }
            }
        } else {
            setCode(undefined);
        }

    }, [level.value, tenant.value, ozmoTenantCode.value, ozmoCodesCache, setOzmoCodesCache, setFieldValue]);

    useEffect(() => {
        if (role.value === UserRoles.SUPERADMIN && level.value < UserLevels.ROOT) {
            roleHelpers.setValue(UserRoles.READER);
        }
    }, [level.value, role.value, roleHelpers]);

    var toggle = (e) => {
        const upn = e.target.value;
        if (upns.value.indexOf(upn) >= 0) {
            upnsHelpers.setValue([...upns.value].filter(o => o !== upn));
        } else {
            upnsHelpers.setValue([...upns.value, upn]);
        }
    };

    var toggleAll = () => {
        if (!isAllSelected()) {
            upnsHelpers.setValue(users.map(o => o.upn));
        } else {
            upnsHelpers.setValue([]);
        }
    };

    var isAllSelected = () => {
        return users != null && upns.value.length === users.length;
    };

    var isSelected = (user) => {
        return upns.value.indexOf(user.upn) >= 0;
    };

    return (
        <>
            <UserScopeEditor readonly={false} />
            <EntityFormField label="Rôle" field={role}>
                <Input {...role}
                    bsSize="sm"
                    disabled={role.value > user.role}
                    type="select"
                    placeholder="Choisissez une valeur"
                    onChange={(e) => roleHelpers.setValue(+(e.target.value))}>
                    {user.role >= UserRoles.READER && <option value={UserRoles.READER}>Lecteur</option>}
                    {user.role >= UserRoles.WRITER && <option value={UserRoles.WRITER}>Rédacteur</option>}
                    {user.role >= UserRoles.ADMIN && <option value={UserRoles.ADMIN}>Administrateur</option>}
                    {user.role >= UserRoles.SUPERADMIN && level.value === UserLevels.ROOT && <option value={UserRoles.SUPERADMIN}>Super Administrateur</option>}
                </Input>
            </EntityFormField>

            <div className="entity-form-field">
                <div className="d-flex">
                    <Label className="me-auto">Sélection des utilisateurs à importer</Label>
                    <button className="btn btn-sm btn-link no-focus-outline p-0 pb-2 lh-1" onClick={toggleAll}>{isAllSelected() ? "Tout désélectionner" : "Tout sélectionner"}</button>
                </div>
                <div className="list-group">
                    {usersLoading &&
                        <label className="list-group-item">
                            <Loader delay={100} className="p-0" />
                        </label>
                    }
                    {!usersLoading && users && users.sort((a, b) => (a.lastName + ' ' + a.firstName).localeCompare((b.lastName + ' ' + b.firstName))).map((user, index) =>
                        <label className={`list-group-item ${isSelected(user) ? "list-group-item-primary" : ""}`} key={user.upn}>
                            <input className="form-check-input me-3" type="checkbox" name="users" value={user.upn} checked={isSelected(user)} onChange={(e) => toggle(e)} />
                            <b>{user.firstName} {user.lastName}</b> ({user.upn})
                        </label>
                    )}
                    {!usersLoading && (!users || users.length === 0) &&
                        <label className="list-group-item list-group-item-disabled">
                            <span className="text-muted">Aucun élément</span>
                        </label>
                    }
                </div>
                <FieldErrorMessage name="upns" />
            </div>
        </>
    );
}

export const ImportUsers = () => {
    const user = useUser();
    const value = {
        ozmoTenantCode: undefined,
        level: Math.min(UserLevels.TENANT, user.level),
        tenant: user.scope.tenant,
        orgUnit: user.scope.orgUnit,
        subOrgUnit: user.scope.subOrgUnit,
        role: UserRoles.READER,
        upns: []
    };
    const validationSchema = yup.object().shape({
        role: yup.number().integer().oneOf([UserRoles.READER, UserRoles.WRITER, UserRoles.ADMIN, UserRoles.SUPERADMIN], "Sélection invalide"),
        level: yup.number().integer().oneOf([UserLevels.SUBORGUNIT, UserLevels.ORGUNIT, UserLevels.TENANT, UserLevels.ROOT], "Sélection invalide"),
        tenant: yup.object().nullable()
            .when('level', (v, s) => {
                if (v <= UserLevels.TENANT) return s.required('Requis');
                else return s;
            }),
        orgUnit: yup.object().nullable()
            .when('level', (v, s) => {
                if (v <= UserLevels.ORGUNIT) return s.required('Requis');
                else return s;
            }),
        subOrgUnit: yup.object().nullable()
            .when('level', (v, s) => {
                if (v === UserLevels.SUBORGUNIT) return s.required('Requis');
                else return s;
            }),
        upns: yup.array().of(yup.string().required('Requis')).required('Requis').min(1, "Au moins un utilisateur doit être sélectionné")
    });

    return (
        <ImportEntitiesForm
            pageTitle="Utilisateurs"
            value={value}
            validationSchema={validationSchema}
            listUrl="/admin/users/"
            save={(value) => Api.importUsers(value)}>
            {(props) => <ImportUsersForm {...props} />}
        </ImportEntitiesForm>
    );
}
