import * as yup from 'yup';
import { scopeSchema } from '../scopes/ScopeValidation';

const promptSchema = yup.object().shape({
    activeType: yup.number().integer()
        .oneOf([1, 2], "Sélection invalide"),
    audioFile: yup.object().nullable()
        .when('activeType', (v, s) => v === 1 ? s.required('Requis') : s),
    textToSpeech: yup.string().nullable()
        .when('activeType', (v, s) => v === 2 ? s.required('Requis').min(10, "Minimum 10 caractères").max(1000, "Maximum 1000 caractères") : s),
});

const thresholdOverrunSchema = yup.object().shape({
    action: yup.number().integer().oneOf([0, 1, 2, 3, 4, 5], "Sélection invalide"),
    agent: yup.object().nullable().when('action', (v, s) => v === 1 ? s.required('Requis') : s),
    callQueue: yup.object().nullable().when('action', (v, s) => v === 2 ? s.required('Requis') : s),
    autoAttendant: yup.object().nullable().when('action', (v, s) => v === 3 ? s.required('Requis') : s),
    sharedVoicemail: yup.object().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    sharedVoicemailPrompt: yup.object().nullable().when('action', { is: 4, then: promptSchema.nullable().required('Requis') }),
    enableSharedVoicemailTranscription: yup.bool().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    externalPstn: yup.string().nullable().when('action', (v, s) => v === 5 ? s.required('Requis') : s),
});

export const callQueueSchema = yup.object().shape({
    scope: scopeSchema.required('Requis'),
    name: yup.string().required('Requis').min(1, 'Minimum 1 caractère').max(64, 'Maximum 64 caractères'),
    language: yup.string().required('Requis'),
    hasWelcomeMusic: yup.bool().required('Requis'),
    welcomeMusic: yup.object().nullable().when('hasWelcomeMusic', (v, s) => v ? s.required('Requis') : s),
    useDefaultMusicOnHold: yup.bool().required('Requis'),
    musicOnHold: yup.object().nullable().when('useDefaultMusicOnHold', (v, s) => !v ? s.required('Requis') : s),
    agents: yup.array().required('Requis').min(1, "Au moins un agent est requis").max(200, "Maximum 200 agents"),
    routingMethod: yup.number().integer().required('Requis').oneOf([0, 1, 2, 3], "Sélection invalide"),
    presenceBasedRouting: yup.bool().required('Requis'),
    conferenceMode: yup.bool().required('Requis'),
    allowOptOut: yup.bool().required('Requis'),
    agentAlertTime: yup.number().integer().required('Requis').min(15, "Minimum 15 secondes").max(180, "Maximum 3 minutes"),
    overflowThreshold: yup.number().integer().required('Requis').min(0, "Minimum 0 appels").max(200, "Maximum 200 appels"),
    overflowThresholdOverrun: thresholdOverrunSchema.required('Requis'),
    timeoutThreshold: yup.number().integer().required('Requis').min(0, "Minimum 0 minutes").max(2700, "Maximum 45 minutes")
        .test(null, 'Doit être un multiple de 15 secondes', (value) => (value % 15) === 0),
    timeoutThresholdOverrun: thresholdOverrunSchema.required('Requis')
});